export default class SearchTransformer {
  constructor(response, language) {
    this.response = response
    this.variants = response.hits
    this.brands = response.facets?.['product.brand'] || []
    this.categories = response.facets?.['product.categories'] || []
    this.hasUnderSaleProducts = !!response.facets?.['under-sale']?.true
    this.minPrice = response.facets_stats?.price?.min || 0
    this.maxPrice = response.facets_stats?.price?.max || 0
    this.language = language
  }

  get transformedData() {
    const products = this.variants.map((variant) =>
      this.productTransformer(variant)
    )
    const brands = this.brands.map((brand) => this.brandTransformer(brand))
    const categories = this.categories.map((category) =>
      this.categoryTransformer(category)
    )

    return {
      products,
      brands,
      categories,
      meta: {
        perPage: this.response.hitsPerPage,
        totalPages: this.response.nbPages,
        totalProducts: this.response.nbSortedHits || this.response.nbHits
      },
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      hasUnderSaleProducts: this.hasUnderSaleProducts
    }
  }

  productTransformer(variant) {
    const product = variant.product
    return {
      id: product.id.toString(),
      brandName:
        product['brand-name'][this.language] || product['brand-name'].en,
      isNew: product['new-arrival'],
      optionType: product['option-type-category'] || 'type',
      pdpUrl: `/products/${product['slug-url']}`,
      productName: product.name[this.language] || product.name.en,
      rating: product.rating,
      reviewsCount: product['reviews-count'],
      variantsCount: product['variants-count'],
      featuredVariant: {
        id: variant.objectID,
        slugUrl: variant['slug-url'],
        name: variant.name[this.language] || variant.name.en,
        originalPrice: variant['display-original-price'],
        price: variant['display-price'],
        saleText: variant['sale-text'],
        onSale: variant['under-sale'],
        imageUrl: variant['image-url'],
        soldOut: variant['sold-out'],
        seal: variant.seal,
        tags: variant.tags,
        sapSku: variant['sap-sku']
      }
    }
  }

  brandTransformer(brand) {
    return {
      id: brand.id,
      name: brand.name[this.language] || brand.name.en,
      slugUrl: brand['slug-url']
    }
  }

  categoryTransformer(category) {
    return {
      id: category.id,
      label: category.label[this.language] || category.label.en,
      slugUrl: category['slug-url'],
      parentId: category['parent-id']
    }
  }
}
